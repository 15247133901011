<template>
    <div class="h-100 d-flex flex-column">
        <nav class="mb-1">
            <div class="nav nav-tabs" id="nav-tab" role="tablist">
                <button
                    @click="ChooseMap"
                    :class="`nav-link ${tab === 'MAP' ? 'active' : ''}`"
                    id="nav-home-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#nav-home"
                    type="button"
                    role="tab"
                    aria-controls="nav-home"
                    :aria-selected="`${tab === 'CARTE'}`"
                >
                    CARTES
                </button>
                <button
                    v-if="!$store.state.login.user.userData.roles.includes('admin_func')"
                    @click="ChooseList"
                    :class="`nav-link ${tab === 'LIST' ? 'active' : ''}`"
                    id="nav-profile-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#nav-profile"
                    type="button"
                    role="tab"
                    aria-controls="nav-profile"
                    :aria-selected="`${tab === 'LIST'}`"
                >
                    INTERVENTIONS
                </button>
            </div>
        </nav>
        <div id="nav-tabContent" class="flex-1">
            <div
                v-if="tab === 'MAP'"
                :class="`h-100 tab-pane fade ${tab === 'MAP' ? 'active show' : 'd-none'}`"
                id="nav-home"
                role="tabpanel"
                aria-labelledby="nav-profile-tab"
            >
                <MissionOrdersMap
                    :mission-orders="missionOrders"
                    :update-in-progress="updateInProgress"
                    class="h-100"
                />
            </div>
            <div
                v-else
                :class="`tab-pane fade ${tab === 'LIST' ? 'active show' : ''}`"
                id="nav-profile"
                role="tabpanel"
                aria-labelledby="nav-home-tab"
            >
                <div
                    class="card"
                    v-if="
                        $store.state.login.user.token && !$store.state.login.user.userData.roles.includes('admin_func')
                    "
                >
                    <MissionOrders />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import MissionOrdersMap from '@/components/MissionOrdersMap'
import MissionOrders from '@/views/MissionOrders'
import { mapState } from 'vuex'

export default {
    props: {
        missionOrders: {
            type: Array,
            required: false,
            default: () => [],
        },
        updateInProgress: {
            type: Boolean,
            required: false,
            default: () => false,
        },
    },
    methods: {
        ChooseMap() {
            this.$store.dispatch('tabs/changeTab', 'MAP')
        },
        ChooseList() {
            this.$store.dispatch('tabs/changeTab', 'LIST')
        },
    },
    components: { MissionOrdersMap, MissionOrders },
    computed: {
        ...mapState('tabs', { tab: 'tab' }),
    },
    mounted() {
        const tab = localStorage.getItem('tab')
        if (tab) {
            this.$store.dispatch('tabs/changeTab', tab)
        }
    },
}
</script>
<style lang=""></style>
