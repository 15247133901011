<template>
    <div class="card">
        <div class="card-header">
            Liste des interventions
            <div uk-spinner="ratio:0.75" v-if="updateInProgress"></div>
        </div>
        <div class="card-body" ref="map" style="min-height: 250px"></div>
    </div>
</template>

<script>
import 'overlapping-marker-spiderfier-leaflet/dist/oms'
import StatusColors from '@/helpers/statusColors'

export default {
    name: 'MissionOrdersMap',
    props: {
        missionOrders: {
            type: Array,
            required: false,
            default: () => [],
        },
        updateInProgress: {
            type: Boolean,
            default: () => false,
        },
    },
    data: () => ({
        map: null,
        markerGroup: null,
    }),
    watch: {
        missionOrders() {
            this.refreshMarkers()
            this.map.flyTo(...this.calculateCenterAndZoom())
        },
    },
    methods: {
        initializeMap() {
            this.map = this.$leaflet.map(this.$refs.map, {
                center: [46.7111, 1.7191],
                zoom: 6,
                gestureHandling: true,
            })
            this.$leaflet
                .tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
                    attribution:
                        '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
                    maxNativeZoom: 25,
                    maxZoom: 25,
                })
                .addTo(this.map)
            this.map.flyTo(...this.calculateCenterAndZoom())
        },
        generateMarker(myCustomColor) {
            const markerHtmlStyles = (myCustomColor) => `
      background-color: ${myCustomColor};
      width: 16px;
      height: 16px;
      display: block;
      position: relative;
      border-radius: 16px 16px 0;
      transform: rotate(45deg);
      border: 1px solid #FFFFFF`

            return this.$leaflet.divIcon({
                className: 'my-custom-pin',
                iconAnchor: [8, 16],
                labelAnchor: [0, 0],
                popupAnchor: [0, 0],
                html: `<span style="${markerHtmlStyles(myCustomColor)}" />`,
            })
        },
        refreshMarkers() {
            const oms = new window.OverlappingMarkerSpiderfier(this.map, { keepSpiderfied: true })
            if (this.markerGroup) {
                this.map.removeLayer(this.markerGroup)
            }
            this.markerGroup = this.$leaflet.layerGroup()
            this.map.addLayer(this.markerGroup)
            const filteredMissionOrders = this.missionOrders
            for (let i = 0; i < filteredMissionOrders.length; i++) {
                let missionOrder = filteredMissionOrders[i]
                const loc = new this.$leaflet.LatLng(
                    missionOrder.property.address.lat,
                    missionOrder.property.address.lng,
                )
                const marker = new this.$leaflet.Marker(loc, {
                    icon: this.generateMarker(StatusColors.getColorForStatus(missionOrder.status.value)),
                })
                this.map.addLayer(marker.addTo(this.markerGroup).bindPopup(this.generatePopupMarker(missionOrder)))
                oms.addMarker(marker)
                oms.addListener('spiderfy', () => {
                    this.map.closePopup()
                })
            }
        },
        generatePopupMarker(missionOrder) {
            let statusText = document.createElement('div')
            statusText.appendChild(document.createTextNode(`${missionOrder.status.value}`))

            let popupText = document.createElement('div')
            popupText.appendChild(document.createTextNode(`${missionOrder.property.address.formattedAddress}`))

            let typesOfinterventions = document.createElement('div')
            typesOfinterventions.appendChild(document.createTextNode(`${missionOrder.g2_inter_code_wording}`))

            let datestart = document.createElement('div')
            datestart.appendChild(
                document.createTextNode(
                    `${this.$util.formatDate(missionOrder.expectedStartDate, 'DD/MM/YYYY', false)}`,
                ),
            )

            let popupActionContainer = document.createElement('div')
            popupActionContainer.classList.add('text-end', 'mt-2')

            let popupActionView = document.createElement('button')
            popupActionView.appendChild(document.createTextNode(`Voir l'ordre`))
            popupActionView.classList.add('btn', 'btn-sm', 'btn-outline-primary')
            popupActionView.addEventListener('click', () => {
                this.$router.push({ name: 'mission_order_view', params: { missionOrderId: missionOrder.id } })
            })

            let popupActionNavigate = document.createElement('a')
            popupActionNavigate.appendChild(document.createTextNode(`Naviguer`))
            popupActionNavigate.classList.add('btn', 'btn-sm', 'btn-outline-primary', 'me-3')
            popupActionNavigate.setAttribute('href', this.navUrl(missionOrder.property.address.formattedAddress))
            popupActionNavigate.setAttribute('role', 'button')
            popupActionNavigate.setAttribute('target', '_blank')

            popupActionContainer.appendChild(popupActionNavigate)
            popupActionContainer.appendChild(popupActionView)

            let popup = document.createElement('div')
            popup.appendChild(statusText)
            popup.appendChild(typesOfinterventions)
            popup.appendChild(datestart)
            popup.appendChild(popupText)
            popup.appendChild(popupActionContainer)

            return popup
        },
        calculateCenterAndZoom() {
            if (this.missionOrders.length < 1) {
                return [[46.7111, 1.7191], 6]
            }

            let lng = [
                parseFloat(this.missionOrders[0].property.address.lng),
                parseFloat(this.missionOrders[0].property.address.lng),
            ]

            let lat = [
                parseFloat(this.missionOrders[0].property.address.lat),
                parseFloat(this.missionOrders[0].property.address.lat),
            ]

            for (let i = 0; i < this.missionOrders.length; i++) {
                let moLat = parseFloat(this.missionOrders[i].property.address.lat)
                let moLng = parseFloat(this.missionOrders[i].property.address.lng)
                if (moLng < lng[0]) {
                    lng[0] = moLng
                }
                if (moLng > lng[1]) {
                    lng[1] = moLng
                }
                if (moLat < lat[0]) {
                    lat[0] = moLat
                }
                if (moLat > lat[1]) {
                    lat[1] = moLat
                }
            }

            let center = [(lat[0] + lat[1]) / 2, (lng[0] + lng[1]) / 2]

            return [center, 6]
        },
        navUrl(formattedAddress) {
            return 'https://www.google.com/maps/dir//' + formattedAddress
        },
    },
    mounted() {
        this.initializeMap()
        this.refreshMarkers()
    },
}
</script>

<style scoped></style>
