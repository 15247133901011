<template>
    <div class="uk-padding-small h-body">
        <Tabs :mission-orders="missionOrders" :update-in-progress="updateInProgress" />
    </div>
</template>

<script>
import store from '@/store'
import Tabs from '@/components/Tabs'
import { mapState } from 'vuex'

export default {
    name: 'HomeDashboard',
    components: { Tabs },
    computed: {
        ...mapState('dashboard', ['missionOrders', 'updateInProgress']),
        ...mapState('login', ['user']),
        ...mapState('missionOrderFilters', ['dateRange', 'selectedStatuses']),
        ...mapState('missionOrderFilters', {
            suezOperatorSelected: (state) => state.suezOperator.selected,
            workerAdminSelected: (state) => state.workerAdmin.selected,
        }),
    },
    watch: {
        'user.token'(val) {
            if (val !== null) {
                store.dispatch('dashboard/updateDashboardData')
            }
        },
    },
}
</script>
